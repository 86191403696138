import dot1 from './Vector-1.svg';
import dot2 from './Vector-2.svg';
import dot3 from './Vector-3.svg';
import dot4 from './Vector-4.svg';
import dot5 from './Vector-5.svg';
import dot6 from './Vector-6.svg';
import dot7 from './Vector-7.svg';
import dot8 from './Vector-8.svg';
import dot9 from './Vector-9.svg';
import dot10 from './Vector-10.svg';
import dot11 from './Vector-11.svg';
import dot12 from './Vector-12.svg';
import dot13 from './Vector-13.svg';
import dot14 from './Vector-14.svg';
import dot15 from './Vector-15.svg';
import dot16 from './Vector-16.svg';
import dot17 from './Vector-17.svg';
import dot18 from './Vector-18.svg';
import dot19 from './Vector-19.svg';
import dot20 from './Vector-20.svg';
import dot21 from './Vector-21.svg';
import dot22 from './Vector-22.svg';
import dot23 from './Vector-23.svg';
import dot24 from './Vector-24.svg';
import dot25 from './Vector-25.svg';
import dot26 from './Vector-26.svg';
import dot27 from './Vector-27.svg';
import dot28 from './Vector-28.svg';
import dot29 from './Vector-29.svg';
import dot31 from './Vector-31.svg';
import dot32 from './Vector-32.svg';
import dot33 from './Vector-33.svg';
import dot34 from './Vector-34.svg';
import dot35 from './Vector-35.svg';
import dot36 from './Vector-36.svg';
import dot37 from './Vector-37.svg';
import dot38 from './Vector-38.svg';
import dot39 from './Vector-39.svg';
import dot40 from './Vector-40.svg';
import dot41 from './Vector-41.svg';
import dot42 from './Vector-42.svg';
import dot43 from './Vector-43.svg';
import dot44 from './Vector-44.svg';
import dot45 from './Vector-45.svg';
import dot46 from './Vector-46.svg';
import dot47 from './Vector-47.svg';
import dot48 from './Vector-48.svg';
import dot49 from './Vector-49.svg';
import dot50 from './Vector-50.svg';
import dot51 from './Vector-51.svg';
import dot52 from './Vector-52.svg';
import dot53 from './Vector-53.svg';
import dot54 from './Vector-54.svg';
import dot55 from './Vector-55.svg';
import dot56 from './Vector-56.svg';
import dot57 from './Vector-57.svg';
import dot58 from './Vector-58.svg';
import dot59 from './Vector-59.svg';
import dot60 from './Vector-60.svg';
import dot61 from './Vector-61.svg';

export default [
  dot1,
  dot2,
  dot3,
  dot4,
  dot5,
  dot6,
  dot7,
  dot8,
  dot9,
  dot10, 
  dot11, 
  dot12, 
  dot13, 
  dot14, 
  dot15, 
  dot16, 
  dot17, 
  dot18, 
  dot19, 
  dot20, 
  dot21, 
  dot22, 
  dot23, 
  dot24, 
  dot25, 
  dot26, 
  dot27, 
  dot28, 
  dot29, 
  dot31, 
  dot32, 
  dot33, 
  dot34, 
  dot35, 
  dot36, 
  dot37, 
  dot38, 
  dot39, 
  dot40, 
  dot41, 
  dot42, 
  dot43, 
  dot44, 
  dot45, 
  dot46, 
  dot47, 
  dot48, 
  dot49, 
  dot50, 
  dot51, 
  dot52, 
  dot53, 
  dot54, 
  dot55, 
  dot56, 
  dot57, 
  dot58, 
  dot59, 
  dot60, 
  dot61, 
];