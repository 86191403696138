// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useGlobalDictionaryQuery, useGlobalLayoutQuery, useLocale } from '../../hooks/index';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles/index';
import formatPrice from '../../utils/formatPrice';
import LinkTool from '../shared/LinkTool';
import StarsReview from '../shared/StarsReview';
import ProductForm from './ProductForm';
import ProductFormDescription from './ProductFormDescription';
import ProductOOSEmail from './ProductOOSEmail';
import ProductFormWithVariantColor from './ProductFormWithVariantColor';
import ProductOOSEmailWithVariantColor from './ProductOOSEmailWithVariantColor';

interface Props {
  title: string;
  oversell: boolean;
  fastShipping: boolean;
  allowAddProtection: boolean;
  slug: string;
  shopifyData: {
    price: string;
    compareAtPrice: string;
    outOfStock: boolean;
  };
  productCategory: {
    category: string;
    subcategory: string;
  };
  description: {
    json: string;
  };
  specs: {
    json: string;
  };
  materials: {
    json: string;
  };
  selectedVariant: {
    price: string;
    title: string;
    outOfStock: string;
    compare_at_price: string;
    quantityAvailable: number;
    sku: string;
  };
  setSelectedVariantState: any;
  updateVariants: any;
  productTag: string;
  protectionProduct: any;
  scrollToReviews: any;
  starRating: number;
  variantItemForOption: any;
  shopifyVariants: {
    node: {
      sku: string;
      quantityAvailable: number;
    };
    thumbnailVariantsImages: any;
  }[];
  imageFromVariantItem: any;
  titleVariantItem: string;
  titleProtectionVariantItem: string;
  protectionExtraItem: any;
}

export default function ProductFormSection(props: Props) {
  const {
    title,
    oversell,
    fastShipping,
    allowAddProtection,
    shopifyData,
    productCategory,
    description,
    specs,
    materials,
    selectedVariant,
    setSelectedVariantState,
    updateVariants,
    productTag,
    protectionProduct,
    scrollToReviews,
    starRating,
    shopifyVariants,
    slug,
    thumbnailVariantsImages,
    variantItemForOption,
    imageFromVariantItem,
    titleVariantItem,
    titleProtectionVariantItem,
    protectionExtraItem,
  } = props;

  const dictionary = useGlobalDictionaryQuery();
  const layout = useGlobalLayoutQuery();
  const colorSale = layout?.colorPriceSale;

  const {
    purchaseAllowed,
    setCategoryFilter,
    maxQuantityCurrentVariant,
    quantityCurrentVariant,
  }: {
    purchaseAllowed: any;
    setCategoryFilter: any;
    maxQuantityCurrentVariant: any;
    quantityCurrentVariant: any;
  } = useContext(SettingsContext);
  const locale = useLocale();
  const [quantity, setQuantity] = useState(1);
  const [deliveryOption, setDeliveryOption] = useState<any>(false);
  const [skyltChecked, setSkyltChecked] = useState(false);
  const [extraItem, setExtraItem] = useState(null);
  const [protectionPabric, setProtectionFabric] = useState(false);

  const handleSetQuantity = itmQuantity => setQuantity(itmQuantity);

  useEffect(() => {
    if (purchaseAllowed && purchaseAllowed.allowed) {
      if (quantity > selectedVariant.quantityAvailable) {
        if (oversell) return setDeliveryOption(dictionary?.slowDelivery);
        return setDeliveryOption('');
      }
      if (skyltChecked) return setDeliveryOption(dictionary?.skyltDelivery);
      if (fastShipping) return setDeliveryOption(dictionary?.quickestDelivery);
      return setDeliveryOption(dictionary?.quickDelivery);
    }
  }, [selectedVariant, quantity, skyltChecked, fastShipping]);

  let quantityVariant = 0;
  if (shopifyVariants?.length > 0) {
    const checkQuantity = shopifyVariants.filter(data => data?.node?.sku === selectedVariant?.sku);
    quantityVariant = checkQuantity[0]?.node?.quantityAvailable;
  }

  const productForm = (
    <ProductForm
      shopifyData={shopifyData}
      skyltChecked={skyltChecked}
      setSkyltChecked={setSkyltChecked}
      selectedVariant={selectedVariant}
      setSelectedVariantState={setSelectedVariantState}
      productTitle={title}
      updateVariants={updateVariants}
      quantity={quantity}
      setQuantity={handleSetQuantity}
      allowAddProtection={allowAddProtection}
      protectionProduct={protectionProduct}
      category={productCategory.category}
      slug={slug}
      oversell={oversell}
      delivery={quantityCurrentVariant > maxQuantityCurrentVariant ? 'slow' : 'quick'}
      thumbnailVariantsImages={thumbnailVariantsImages}
      variantItemForOption={variantItemForOption}
      imageFromVariantItem={(e: any) => imageFromVariantItem(e)}
      titleVariantItem={titleVariantItem}
      titleProtectionVariantItem={titleProtectionVariantItem}
      protectionExtraItem={protectionExtraItem}
      getExtraItem={(item: any) => setExtraItem(item)}
      getProtectionfabric={(item: any) => setProtectionFabric(item)}
    />
  );
  const productFormWithVariantColor = (
    <ProductFormWithVariantColor
      shopifyData={shopifyData}
      skyltChecked={skyltChecked}
      setSkyltChecked={setSkyltChecked}
      selectedVariant={selectedVariant}
      setSelectedVariantState={setSelectedVariantState}
      productTitle={title}
      updateVariants={updateVariants}
      quantity={quantity}
      setQuantity={handleSetQuantity}
      allowAddProtection={allowAddProtection}
      protectionProduct={protectionProduct}
      category={productCategory.category}
      slug={slug}
      oversell={oversell}
      delivery={quantityCurrentVariant > maxQuantityCurrentVariant ? 'slow' : 'quick'}
      thumbnailVariantsImages={thumbnailVariantsImages}
    />
  );

  const DeliveryShippingTitle = (quantityVariant: number) => {
    let statusExtraItem = false;
    let extraItemQuickDelivery = false;

    if (extraItem) {
      statusExtraItem = true;
      if (extraItem?.shopifyData?.variants[0]?.inventory_quantity >= quantity) {
        extraItemQuickDelivery = true;
      } else {
        extraItemQuickDelivery = false;
      }
    } else {
      statusExtraItem = false;
      extraItemQuickDelivery = false;
    }

    if (quantityVariant?.quantityCurrentVariant > quantityVariant?.maxQuantityCurrentVariant) {
      return <>{dictionary.slowDelivery}</>;
    } else {
      if (statusExtraItem && extraItemQuickDelivery) {
        if (quantityVariant?.skyltChecked) {
          return <>{dictionary?.deliveryWhenStatusInStockAndAddProtection}</>;
        } else {
          if (protectionPabric) {
            return <>{dictionary?.deliveryWhenStatusInStockAndAddProtection}</>;
          } else {
            return <>{dictionary.quickDelivery}</>;
          }
        }
      } else if (!statusExtraItem) {
        if (protectionPabric || quantityVariant?.skyltChecked) {
          return <>{dictionary?.deliveryWhenStatusInStockAndAddProtection}</>;
        } else {
          return <>{dictionary.quickDelivery}</>;
        }
      } else {
        return <>{dictionary.slowDelivery}</>;
      }
    }
  };
  return (
    <Wrapper>
      <Title>
        <Breadcrumbs>
          <span onClick={() => setCategoryFilter(productCategory.category)}>
            <LinkTool to="/all-products/">{productCategory.category}</LinkTool>
          </span>
        </Breadcrumbs>
        <ProductTitle>{title}</ProductTitle>
        <ProductWrapper>
          {purchaseAllowed?.allowed ? (
            <>
              {Number(selectedVariant.price) < Number(selectedVariant.compare_at_price) ? (
                <ProductPrice style={{ color: colorSale }}>
                  {formatPrice(selectedVariant.price, '€')}
                </ProductPrice>
              ) : (
                <ProductPrice>{formatPrice(selectedVariant.price, '€')}</ProductPrice>
              )}
              {Number(selectedVariant.compare_at_price) > Number(selectedVariant.price) && (
                <>
                  <ProductPrice isDiscount="potato">
                    {formatPrice(selectedVariant.compare_at_price, '€')}
                    <div className="strikeTrough" />
                  </ProductPrice>
                  {/* {productTag && productTag?.toLowerCase() === 'no' && (
                    <SaleRibbon>
                      <p>{dictionary?.pdpSaleText}</p>
                    </SaleRibbon>
                  )} */}
                </>
              )}
            </>
          ) : null}

          {productTag && productTag?.toLowerCase() !== 'no' && (
            <TagRibbon>
              <p>{productTag}</p>
            </TagRibbon>
          )}
          {/* {selectedVariant.inventory_quantity > 0 ? (
            <TagRibbon>{locale === 'nl' ? <p>Voorraad</p> : <p>In Stock</p>}</TagRibbon>
          ) : productTag?.toLowerCase() === 'new' || productTag?.toLowerCase() === 'nieuw' ? (
            <TagRibbon>
              <p>{locale == 'nl' ? 'Voorafgaande Bestelling' : 'Pre Order'}</p>
            </TagRibbon>
          ) : null} */}
        </ProductWrapper>
        {starRating ? (
          <ReviewWrapper onClick={scrollToReviews}>
            <p>Reviews </p> <StarsReview starsQuantity={5} totalStars={starRating} />
          </ReviewWrapper>
        ) : null}
      </Title>
      <Form>
        {selectedVariant.outOfStock ? (
          oversell ? (
            shopifyData.variants[0]?.title?.includes(' / ') ? (
              productFormWithVariantColor
            ) : (
              productForm
            )
          ) : shopifyData.variants[0]?.title?.includes(' / ') ? (
            <ProductOOSEmailWithVariantColor
              shopifyData={shopifyData}
              selectedVariant={selectedVariant}
              setSelectedVariantState={setSelectedVariantState}
              productTitle={title}
              updateVariants={updateVariants}
              oversell={oversell}
              totalQuantity={quantityVariant}
              thumbnailVariantsImages={thumbnailVariantsImages}
            />
          ) : (
            <ProductOOSEmail
              shopifyData={shopifyData}
              selectedVariant={selectedVariant}
              setSelectedVariantState={setSelectedVariantState}
              productTitle={title}
              updateVariants={updateVariants}
              oversell={oversell}
              totalQuantity={quantityVariant}
            />
          )
        ) : shopifyData.variants[0]?.title?.includes(' / ') ? (
          productFormWithVariantColor
        ) : (
          productForm
        )}
      </Form>

      {purchaseAllowed?.allowed ? (
        <>
          {deliveryOption && (
            <Shipping>
              <DeliveryShippingTitle
                quantityVariant={quantityVariant}
                skyltChecked={skyltChecked}
                quantityCurrentVariant={quantityCurrentVariant}
                maxQuantityCurrentVariant={maxQuantityCurrentVariant}
                // extraItemQuantity={extraItemQuantity}
                // extraItemOversell={extraItemOversell}
              />
              {/* {quantityVariant > 0 ? (
                <>
                  {skyltChecked ? (
                    <>{dictionary?.deliveryWhenStatusInStockAndAddProtection}</>
                  ) : (
                    <>
                      {quantityCurrentVariant > maxQuantityCurrentVariant
                        ? dictionary.slowDelivery
                        : dictionary.quickDelivery}
                    </>
                  )}
                </>
              ) : (
                <>{dictionary.slowDelivery}</>
              )} */}
            </Shipping>
          )}
        </>
      ) : null}
      <Description>
        <ProductFormDescription description={description} specs={specs} materials={materials} />
      </Description>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: grid;
  grid-template: "title" "form" "reviewswrapper" "shipping" "description";
  width: 85vw;
  height: auto;


  ${desktopBreakpoint}{
    grid-template: "title" "description" "form" "shipping" "reviewswrapper";
    width: ${desktopVW(600)};
    height: auto;
    //padding-top: ${desktopVW(100)};
    padding-top: ${desktopVW(20)};
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: title;
  padding: ${mobileVW(25)} 0;

  ${desktopBreakpoint} {
    padding: ${desktopVW(0)} 0 ${desktopVW(15)} 0;
    border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
  }
`;

const ReviewWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin: 10px 0 0px 0;
  p {
    text-decoration: underline;
    margin-right: 8px;
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    color: ${colors.black};
    font-size: ${mobileVW(18)};
  }

  ${desktopBreakpoint} {
    p {
      font-size: ${desktopVW(14)};
    }
  }
`;

const ProductTitle = styled.div`
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  width: 100%;
  color: ${colors.black};
  font-size: ${mobileVW(30)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(26)};
    letter-spacing: ${desktopVW(-2)};
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  ${desktopBreakpoint} {
  }
`;

const ProductPrice = styled.div`
  position: relative;
  display: inline;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  font-size: ${mobileVW(18)};
  font-weight: 300;
  color: ${colors.subtleGrey};
  padding: ${mobileVW(10)} ${mobileVW(10)} ${mobileVW(10)} 0;
  ${({ isDiscount }) => (isDiscount ? 'opacity: 0.5;' : null)}

  .strikeTrough {
    width: 90%;
    height: ${mobileVW(1.5)};
    position: absolute;
    left: 0;
    top: 53%;
    transform: translate(-3%, -50%);
    background-color: ${colors.subtleGrey};

    ${desktopBreakpoint} {
      height: ${desktopVW(1.5)};
      top: 50%;
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    font-size: ${desktopVW(15)};
    padding: ${desktopVW(5)} ${desktopVW(7)} ${desktopVW(5)} 0;
  }
`;

const SaleRibbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${desktopVW(20)};

  p {
    font-family: 'messina', Arial, Helvetica, sans-serif;
    font-size: ${mobileVW(12)};
    font-weight: 300;
    text-transform: uppercase;
    color: ${colors.cappuccino};
    background-color: ${colors.black};
    padding: ${mobileVW(5)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(11)};
      padding: ${desktopVW(3)};
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    padding-right: ${desktopVW(5)};
  }
`;

const TagRibbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${desktopVW(20)};

  p {
    font-family: 'messina', Arial, Helvetica, sans-serif;
    font-size: ${mobileVW(12)};
    font-weight: 300;
    text-transform: uppercase;
    color: ${colors.black};
    background-color: ${colors.lightGrey};
    padding: ${mobileVW(5)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(10)};
      padding: ${desktopVW(5)};
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    padding-right: ${desktopVW(5)};
  }
`;

const Breadcrumbs = styled.div`
  display: none;

  p {
    color: ${colors.black};
    text-decoration: underline;
    display: inline;
  }

  ${desktopBreakpoint} {
    display: block;
    font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
    width: 100%;
    font-weight: 300;
    color: ${colors.subtleGrey};
    justify-content: flex-start;
    font-size: ${desktopVW(13)};
    padding: ${desktopVW(13)} 0;
  }
`;

const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: form;
`;

const Shipping = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: shipping;
  padding: ${mobileVW(20)} 0;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};

  ${desktopBreakpoint} {
    padding: ${desktopVW(20)} 0;
    font-size: ${desktopVW(14)};
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: description;
`;
