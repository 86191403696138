// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { animated, useSpring, config } from 'react-spring';
import { desktopBreakpoint, colors, desktopVW, zIndex } from '../../styles/index';
import Freckles from '../shared/Freckles';
import paper from '../../../static/paperBackground/paper.jpeg';
import ProductFormSection from './ProductFormSection';

interface Props {
  title: string;
  oversell: boolean;
  fastShipping: boolean;
  allowAddProtection: boolean;
  slug: string;
  shopifyData: {
    price: string;
    compareAtPrice: string;
    outOfStock: boolean;
  };
  productTag: string;
  productImages: [
    {
      title: string;
      fluid: string;
    }
  ];
  thumbnailProductImages: [
    {
      title: string;
      fluid: string;
    }
  ];
  variantsImages: [
    {
      variantTitle: string;
      images: [
        {
          title: string;
          fluid: string;
        }
      ];
    }
  ];
  thumbnailVariantsImages: [
    {
      variantTitle: string;
      images: [
        {
          title: string;
          fluid: string;
        }
      ];
    }
  ];
  productCategory: {
    category: string;
    subcategory: string;
  };
  description: {
    json: string;
  };
  specs: {
    json: string;
  };
  selectedVariant: {
    price: string;
    title: string;
    outOfStock: string;
    compare_at_price: string;
  };
  setSelectedVariantState: any;
  updateVariants: any;
  protectionProduct: any;
  scrollToReviews: any;
  starRating: number;
  variantItemForOption: any;
  titleVariantItem: string;
  titleProtectionVariantItem: string;
}

export default function ProductMainDesktop(props: Props) {
  const {
    title,
    oversell,
    slug,
    fastShipping,
    allowAddProtection,
    shopifyData,
    productImages,
    thumbnailProductImages,
    variantsImages,
    thumbnailVariantsImages,
    productCategory,
    description,
    specs,
    selectedVariant,
    setSelectedVariantState,
    updateVariants,
    productTag,
    protectionProduct,
    scrollToReviews,
    starRating,
    shopifyVariants,
    variantItemForOption,
    titleVariantItem,
    titleProtectionVariantItem,
  } = props;

  const [cursor, setCursor] = useState(0);
  const [scrollCursor, setScrollCursor] = useState(0);
  const [images, setImages] = useState([]);
  const [imageFromOption, setImageFromOption] = useState(null);
  const imageFromVariantItem = (e: any) => {
    setImageFromOption(e?.productImages);
  };
  const [slidesSpring, setSlidesSpring] = useSpring(() => ({
    transform: 'translateY(0vh)',
    config: {
      mass: 1.8,
      tension: 190,
      friction: 30,
    },
  }));

  const [, setY] = useSpring(() => ({
    y: 0,
    config: {
      mass: 2,
      tension: 180,
      friction: 35,
      clamp: true,
    },
  }));

  const centerImage = index => {
    setY({
      y: window.innerHeight * index,
      reset: true,
      from: { y: window.scrollY },
      onFrame: props => window.scroll(0, props.y),
    });
    setCursor(index);
  };

  const containerRef = useRef();

  const [zoomActive, setZoomActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState('');

  const handleZoomClick = (index, x, y) => {
    setActiveIndex(index);
    centerImage(index);

    zoomActive ? handleZoomStop() : handleZoomStart(x, y);
  };

  const calcXY = (x, y) => {
    const resultX = (containerRef.current.clientWidth / 2 - x) / 1.8;
    const resultY = (window.innerHeight / 2 - y) / 1.8;
    return [resultX, resultY];
  };

  const [propsXY, setPropsXY] = useSpring(() => ({
    xy: [0, 0],
    scale: 1,
    config: { mass: 1, tension: 170, friction: 26 },
  }));

  const handleZoomStart = (x, y) => {
    setPropsXY({ xy: calcXY(x, y), scale: 2 });
    setZoomActive(true);
  };

  const handleZoomStop = () => {
    setPropsXY({ xy: [0, 0], scale: 1 });
    setZoomActive(false);
  };

  const handleMove = (index, x, y) => {
    if (activeIndex === index && zoomActive) {
      setPropsXY({ xy: calcXY(x, y), scale: 2 });
    }
  };

  const trans = (x, y) => `scale(${zoomActive ? 2 : 1}) translate(${x}px, ${y}px)`;

  const cursorWrapper = useSpring({
    opacity: zoomActive ? 0 : 1,
    config: { mass: 1, tension: 170, friction: 26 },
  });

  // const selectedVariantImages = variantsImages?.find(
  //   images => images.variantTitle === selectedVariant.title
  // );
  // const selectedThumbnailVariantImages = thumbnailVariantsImages?.find(
  //   images => images.variantTitle === selectedVariant.title
  // );

  // const allImages = selectedVariantImages?.images
  //   ? [...selectedVariantImages.images, ...productImages]
  //   : productImages;

  // const allThumbnailImages = selectedThumbnailVariantImages?.images
  //   ? [...selectedThumbnailVariantImages.images, ...thumbnailProductImages]
  //   : thumbnailProductImages;

  const selectedVariantImages = variantsImages?.find(
    images => images?.variantTitle === selectedVariant?.title
  );

  function removeDuplicatesByTitle(data) {
    if (data) {
      const seenTitles = new Set();
      let filter = data.filter(item => {
        if (seenTitles?.has(item?.title)) {
          return false;
        } else {
          seenTitles.add(item?.title);
          return true;
        }
      });
      return filter?.filter(item => item != null);
    } else {
      return productImages;
    }
  }

  useEffect(() => {
    let combinedArray = selectedVariantImages?.images.concat(productImages);
    if (imageFromOption) {
      combinedArray = imageFromOption;
    }
    setImages(combinedArray);
  }, [selectedVariantImages, imageFromOption]);

  return (
    <>
      <Wrapper>
        <SlidesWrapper style={{ ...slidesSpring }} ref={containerRef}>
          {removeDuplicatesByTitle(images)?.map((item, index) => (
            <SlideWrapper key={item?.title}>
              <Background />
              <ImageWrapper
                indexImg={index}
                onClick={({ clientX: x, clientY: y }) => handleZoomClick(index, x, y)}
                onMouseLeave={() => handleZoomStop()}
                onMouseMove={({ clientX: x, clientY: y }) => handleMove(index, x, y)}
                zoomActive={zoomActive}
              >
                <StyledImg
                  fluid={item?.fluid}
                  loading="eager"
                  indexImg={index}
                  objectFit={index === 0 && 'contain'}
                  style={activeIndex === index && { transform: propsXY.xy.interpolate(trans) }}
                />
              </ImageWrapper>
              <FrecklesWrapper>
                <Freckles canvasHeight="100vh" />
              </FrecklesWrapper>
            </SlideWrapper>
          ))}
        </SlidesWrapper>
        <ProductFormWrapper zoomActive={zoomActive}>
          <CursorWrapper>
            <RelativeWrapper>
              <CursorWrapperInner style={{ ...cursorWrapper }}>
                {removeDuplicatesByTitle(images)?.map((item, index) => (
                  <CursorButton
                    key={item?.title}
                    cursor={cursor}
                    index={index}
                    scrollCursor={scrollCursor}
                    onClick={() => centerImage(index)}
                  >
                    <StyledImg fluid={item?.fluid} />
                  </CursorButton>
                ))}
              </CursorWrapperInner>
            </RelativeWrapper>
          </CursorWrapper>
          <ProductFormSection
            shopifyData={shopifyData}
            title={title}
            description={description}
            specs={specs}
            selectedVariant={selectedVariant}
            setSelectedVariantState={setSelectedVariantState}
            oversell={oversell}
            fastShipping={fastShipping}
            allowAddProtection={allowAddProtection}
            updateVariants={updateVariants}
            productCategory={productCategory}
            productTag={productTag}
            protectionProduct={protectionProduct}
            scrollToReviews={scrollToReviews}
            starRating={starRating}
            shopifyVariants={shopifyVariants}
            slug={slug}
            thumbnailVariantsImages={thumbnailVariantsImages}
            variantItemForOption={variantItemForOption}
            imageFromVariantItem={(e: any) => imageFromVariantItem(e)}
            titleVariantItem={titleVariantItem}
            titleProtectionVariantItem={titleProtectionVariantItem}
          />
        </ProductFormWrapper>
      </Wrapper>
      <Spacer />
    </>
  );
}

const RelativeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Wrapper = styled.section`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  background: none;
  background-size: ${desktopVW(10)} ${desktopVW(10)};
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(${paper});
  background-repeat: repeat;
  z-index: 0;
  ${desktopBreakpoint} {
  }
`;

const SlideWrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: visible;
  background: url(${paper});
  background-repeat: repeat;
  width: 58vw;
`;

const SlidesWrapper = styled(animated.div)`
  width: 58vw;
  height: auto;
  position: relative;
`;

const Spacer = styled.div`
  width: 100vw;
  height: ${desktopVW(50)};
  background: url(${paper});
  background-repeat: repeat;
`;

const StyledImg = styled(animated(Img))`
  height: 100%;
  max-height: ${({ indexImg }) => (indexImg === 0 ? `${desktopVW(820)}` : '100%')};
  width: ${({ indexImg }) => (indexImg === 0 ? `100%` : '100%')};
  transform-origin: center;
  position: absolute;
  img {
    object-fit: cover;
  }
`;

const ImageWrapper = styled(animated.div)`
  position: relative;
  height: 100%;
  width: 58vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.backgrounds};
  padding: ${({ indexImg }) => (indexImg === 0 ? `${desktopVW(50)}` : 0)};
  overflow: hidden;
  cursor: ${({ zoomActive }) => (zoomActive ? 'zoom-out' : 'zoom-in')};
  background: ${({ zoomActive }) => (zoomActive ? colors.fakeProductBackground : null)};
`;

const FrecklesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const CursorWrapperInner = styled(animated.div)`
  margin-top: 50vh;
  transform: translate(-60.5vw, -50%);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-row-gap: ${desktopVW(15)};
  z-index: ${zIndex.surface};
`;

const CursorWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.surface};
`;

const CursorButton = styled.div`
  height: ${desktopVW(40)};
  width: ${desktopVW(40)};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  background-color: white;

  &:hover {
    transform: scale(1.3);
  }
`;

const ProductFormWrapper = styled.div`
  position: sticky;
  top: 5vw;
  height: 100vh;
  min-height: ${desktopVW(480)};
  width: 45vw;
  display: flex;
  align-items: center;
  z-index: ${zIndex.surface + 1};
  padding: 0px 10vw 0 ${desktopVW(83)};
  pointer-events: ${({ zoomActive }) => (zoomActive ? 'none' : 'auto')};
`;
