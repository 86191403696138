import React, {useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import elements from '../../../static/dots/index'

interface Props {
  canvasHeight: any
}

export default function Freckles(props: Props) {

  const {
    canvasHeight, 
  } = props

  const [ready, setReady] = useState(false)

  const canvas = useRef()

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // CHANGE THE CANVAS HEIGHT



  useLayoutEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    const can = canvas.current
    const canWidth = can.getBoundingClientRect().width
    const canHeight = can.getBoundingClientRect().height
    const ctx = can.getContext('2d')
    can.width = canWidth
    can.height = canHeight
    elements.forEach((item, i)=>{
      let dot = new Image()
      dot.src = item
      const size = getRandomInt(0.1, 6)
      setTimeout(()=>ctx.drawImage(dot, getRandomInt(10, canWidth), getRandomInt(10, (canHeight)), size, size), 100)
    })
    // elements.forEach((item, i)=>{
    //   let dot = new Image()
    //   dot.src = item
    //   const size = getRandomInt(0.1, 7)
    //   setTimeout(()=>ctx.drawImage(dot, getRandomInt(10, window.innerWidth), getRandomInt(10, (window.innerHeight)), size, size), 100)
    // })
  }, [elements[1]])

  return (
    <DotsCanvas 
      ref={canvas}
      onClick={()=>setReady(!ready)}
      canvasHeight={canvasHeight}
    />
  )
}

const DotsCanvas = styled.canvas`
  width: 100vw;
  height: ${({canvasHeight})=>canvasHeight};
`;