import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, DesktopView, MobileView, SpringConfig, zIndex } from '../../styles/index'
import { animated, useSpring, config } from 'react-spring'
import { useGlobalDictionaryQuery } from '../../hooks'

interface Props {
  inputValue: string
  inputSetValue: any
  color: string
  width: string
  widthDesk: string
  placeholder: string
  fontSize: string
  fontSizeDesk: string
  onChangecallback: any
  validation: string
  SetContainErrors: any
  inputType: string
  inputName: string
  required: boolean
}

export default function ProductOOSEmailInput(props: Props) {

  const [isFocused, setIsFocused] = useState(false)
  const [isError, setIsError] = useState(false)

  const {
    inputValue: value,
    inputSetValue: setValue,
    inputType,
    inputName,
    color,
    width,
    widthDesk,
    placeholder,
    fontSize,
    fontSizeDesk,
    onChangecallback,
    validation,
    setContainErrors,
    children,
    required,
    //validation,
  } = props

  const handleChange = (e) => {
    setValue(e.target.value)
    onChangecallback(e)
  }

  const dictionary = useGlobalDictionaryQuery();

  const placeholderSpring = useSpring({
    transform: isFocused ? `translate(${desktopVW(-10)}, ${desktopVW(-23)}) scale(0.7)` : value.length > 0 ? `translate(${desktopVW(-10)}, ${desktopVW(-23)}) scale(0.7)` : `translate(${desktopVW(0)}, ${desktopVW(0)}) scale(1)`,
    //color: isFocused ? colors.black : colors.lightGrey,
    config: SpringConfig.microInteraction
  })

  const placeholderSpringMobile = useSpring({
    transform: isFocused ? `translateY(-15%, ${mobileVW(-28)}) scale(0.75)` : value.length > 0 ? `translateY(-15%, ${mobileVW(-28)}) scale(0.75)` : `translate(0%, ${mobileVW(0)}) scale(1)`,
    //color: isFocused ? colors.black : colors.lightGrey,
    config: SpringConfig.microInteraction
  })

  // const colorBorderBottom = () => {
  //   if (isFocused) {
  //     return colorBorder
  //   } else {
  //     if (value.length > 0) {
  //       if (isError) {
  //         return 'red'
  //       } else {
  //         return initialColorBorder ? initialColorBorder : 'rgba(0, 0, 0, 0.4)'
  //       }
  //     } else {
  //       return initialColorBorder ? initialColorBorder : 'rgba(0, 0, 0, 0.4)'
  //     }
  //   }
  // }

  useEffect(() => {
    if (!isFocused) {
      if(value.length > 0){
        switch (validation) {
          case 'onlyLetters':
            if (!/^[a-zA-Z\s]*$/.test(value)) {
              setIsError(true)
              setContainErrors(true)
            } else {
              setIsError(false)
              setContainErrors(false)
            }
            break
          case 'onlyNumbers':
            if (!/^[0-9\s]*$/.test(value)) {
              setIsError(true)
              setContainErrors(true)
            } else {
              setIsError(false)
              setContainErrors(false)
            }
            break
          case 'onlyEmail':
            if (value.length > 0) {
              if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/.test(value)) {
                setIsError(true)
                setContainErrors(true)
              } else {
                setIsError(false)
                setContainErrors(false)
              }
            }
            break
          case 'password':
            if (value.length > 0) {
              if (/^.*(?=.{6,20})(?=.*[a-z])(?=.*[A-Z]).*$/.test(value) === false) { //(?=.*\d)
                setIsError(true)
                setContainErrors(true)
              } else {
                setIsError(false)
                setContainErrors(false)
              }
            }
            break
        }
      } else {
        setIsError(false)
        setContainErrors(true)
      }

    }
  }, [isFocused])

  const errorText = () => {
    if (validation === "onlyLetters") {
      return dictionary.pleaseEnterOnlyLatinLetters
    }
    if (validation === "onlyEmail") {
      return dictionary.pleaseEnterAValidEmailAddress
    }
    if (validation === 'password') {
      return dictionary.atLeastNCharactersWithNCapitalLetter
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper>
        <DesktopView>
          <StyledPlaceholder
            style={{ ...placeholderSpring }}
            fontsizedesk={fontSizeDesk}
            
          >
            {placeholder}
          </StyledPlaceholder>
        </DesktopView>
        <MobileView>
          <StyledPlaceholder
            style={{ ...placeholderSpringMobile }}
            fontSize={fontSize}
          >
            {placeholder}
          </StyledPlaceholder>
        </MobileView>
        <StyledInput
          type={inputType}
          name={inputName}
          //value={}
          placeholder=''
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          required={required}
          autoComplete="off"
          color={color}
          width={width}
          widthDesk={widthDesk}
          fontSize={fontSize}
          fontsizedesk={fontSizeDesk}
          isFocused={isFocused}
          value={value}
        />
      </Wrapper>
      <ErrorMsg
        validation={validation}
        isError={isError}
        fontsizedesk={fontSizeDesk}
        isFocused={isFocused}
      >
        {errorText()}
      </ErrorMsg>
      {children}
    </div>
  )
}

const Wrapper = styled.div`
  position: relative;
  //margin: ${mobileVW(60)} 0;
  font-family: 'messinaMonoLight';
  z-index: ${zIndex.surface};

  ${desktopBreakpoint}{
    //margin: ${desktopVW(32)} 0; 
  }
`;

const StyledInput = styled.input`
  position: relative;
  font-family: 'messinaMono';
  text-align: left;
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${mobileVW(24)};
  border-radius: 0;
  border: none;
  flex: 1 0 auto;
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  transition: 0.25s;
  //padding-bottom: ${({ fontSize }) => fontSize};
  //border: ${mobileVW(1)} solid ${colors.lightGrey};
  padding: ${mobileVW(18)} ${mobileVW(18)};
  z-index: ${zIndex.surface};


  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ color }) => color};
  }

  &:-ms-input-placeholder {
    color: ${({ color }) => color};
  }

  &::-ms-input-placeholder {
    color: ${({ color }) => color};
  }

  &::-webkit-search-cancel-button{
    -webkit-appearance: none;
  }

  ${desktopBreakpoint}{
    font-size: ${({ fontsizedesk }) => fontsizedesk};
    line-height: ${desktopVW(14)};
    width: ${({ widthDesk }) => widthDesk};
    padding: ${desktopVW(18)} ${desktopVW(10)};
  }
`;

const StyledPlaceholder = styled(animated.div)`
    position: absolute;
    pointer-events: none;
    bottom: ${mobileVW(17)};
    left: ${mobileVW(18)};
    font-size: ${({ fontSize }) => fontSize};
    padding: 0 ${mobileVW(3)};
  ${desktopBreakpoint}{
    bottom: ${desktopVW(18)};
    left: ${desktopVW(10)};
    font-size: ${({ fontsizedesk }) => fontsizedesk};
    padding: 0 ${desktopVW(3)};
  }
`;

const ErrorMsg = styled.p`
  font-family: 'messinaMonoLight';
  text-align: right;
  color: ${colors.tomato};
  position: absolute;
  font-size: ${mobileVW(13)};
  transform: ${`translateY(${mobileVW(5)})`};
  bottom: ${mobileVW(-2)};
  left: ${mobileVW(18)};
  translate: 0.2s;
  display: inline;

  ${
  ({ isError, isFocused }) => (
    isError
      &&
      !isFocused
      ?
      `opacity: 1;`
      :
      `opacity: 0;`
  )
  }

  ${desktopBreakpoint}{
    bottom: ${desktopVW(15)};
    left: ${desktopVW(7)};
    //transform: ${`translateY(${desktopVW(10)})`};
    font-size: ${desktopVW(12)};
    padding: 0 ${desktopVW(3)};
  }
`;